<template>
  <div class="block w-full py-1">
    <div class="grid grid-cols-12 w-full">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
            <item-text title="SKU" :text="item.sku" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-4">
            <item-text title="Nosaukums" :text="item.catalog_item" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <item-text title="Daudzums noliktavā" :text="item.quantity_in_stock" />
        </div>

        <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
            <item-text title="Saskaitīts" :text="item.new_quantity" :textClass="item.quantity_in_stock !== item.new_quantity ? 'text-red-400 dark:text-red-400' : ''" />
        </div>
    </div>
  </div>
</template>

<script>
import ItemText from "@/components/Components/ItemText"

export default {
  name: "SingleStockCheckItem",
  components: {
    ItemText,
  },
  props: {
    item: {
      type: Object,
      required: true,
    }
  },
}
</script>

<style>

</style>