<template>
    <item-card mb="5" :content="showFullList">
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mt-2 flex justify-between w-full">
                <span class="mr-2">
                    Pozīcijas - {{stockCheck.total_positions}}
                </span>
                <span class="mr-2">
                    Pozīciju summa - {{stockCheck.total_count}}
                </span>

                <template v-if="!stockCheck.is_accepted">
                    <button type="button" class="bg-green-100 dark:bg-green-100 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-green-800 dark:text-green-800 hover:bg-green-200 dark:hover:bg-green-200 focus:outline-none focus:ring-0 mr-8" @click="accept">
                        Akceptēt
                    </button>
                </template>
                <template v-if="stockCheck.is_accepted && stockCheck.accepted">
                    <Badge :text="stockCheck.accepted.user + ' ' + stockCheck.accepted.accepted_at" class="bg-green-100 text-green-800 mr-2 mb-2 md:mb-0" />
                </template>
            </h3>
        </template>

        <template v-slot:buttons>
            <template v-if="!stockCheck.is_accepted && stockCheck.items.length > 0">
                <Button :icon="showFullList ? 'chevron-up' : 'chevron-down'" tooltip="Atšķirīgie ieraksti" @click="showFullList = !showFullList" />
            </template>
        </template>
        
        <template v-slot:content>
            <template v-if="stockCheck.items">
                <div class="px-4 w-full divide-y divide-gray-200 dark:divide-gray-600">
                    <template v-for="(item, index) in allItems" :key="index">
                        <SingleStockCheckItem :item="item" />
                    </template>
                </div>
            </template>
        </template>
    </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import Badge from "@/components/Components/Badge"
import SingleStockCheckItem from "@/components/Stock/StockChecks/StockCheckItems/SingleStockCheckItem"

export default {
    name: "StockCheckItems",
    components: {
        ItemCard,
        Badge,
        SingleStockCheckItem,
    },
    props: ['stockCheck'],
    mounted() {
        if(this.stockCheck.items) {
            this.showFullList ? this.allItems = [...this.stockCheck.items] : this.allItems = [...this.stockCheck.items].slice(0, 0)
        }
    },
    data() {
        return {
            allItems: [],
            showFullList: false,
        }
    },
    watch: {
        showFullList() {
            this.showFullList ? this.allItems = [...this.stockCheck.items] : this.allItems = [...this.stockCheck.items].slice(0, 0)
        },
    },
    methods: {
        accept() {
            if(confirm('Vai esi drošs?')) {
                this.$Progress.start()
                this.$store.dispatch('acceptStockCheck', this.stockCheck.id)
                this.showFullList = false
            }
        }
    }
}
</script>

<style>

</style>